@import "ui/styles/2-tools/tools.media-query.scss";

.SearchResultWrapper {
	&_top_header {
		border-bottom: var(--border--sm) solid var(--color-mediumGrey);
		width: 100%;
		padding: var(--spacing--2xl) 0 var(--spacing--lg) 0;
		margin-bottom: var(--spacing--xl);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: var(--spacing--sm) var(--spacing--xl);

		@include mq("md") {
			flex-direction: row;
			margin-bottom: var(--spacing--3xl);
			padding-top: var(--spacing--3xl);
		}
	}

	&_side__left {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
	}

	&_side__right {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		width: 100%;

		@include mq("md") {
			flex-basis: 15%;
			justify-content: flex-end;
			width: auto;
		}
	}

	&_results {
		@include mq("lg") {
			padding-left: var(--spacing--5xl);
			padding-right: var(--spacing--5xl);
		}
	}

	&_spinner {
		margin-top: var(--spacing--xl);

		@include mq("md") {
			margin-top: var(--spacing--3xl);
		}
	}
}
