@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.media-query.scss";

.ArticleTable {
	position: relative;
	margin-bottom: var(--spacing--2xl);
	mask: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 12rem, rgba(255, 255, 255, 1) 100%);
	transition: mask 0.3s ease-in-out;
	overflow-x: auto;

	@include mq("md") {
		margin-bottom: var(--spacing--3xl);
		mask: linear-gradient(
			to left,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 1) 20rem,
			rgba(255, 255, 255, 1) 100%
		);
	}

	&.isScrolled {
		mask: none;
	}

	table {
		th {
			white-space: nowrap;
		}
	}

	a {
		@include action;
	}
}
