@import "ui/styles/2-tools/tools.list-mixins.scss";

.SocialLinks {
	@include reset-list;

	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing--xs);
}

.SocialLinks_link {
	color: var(--color-dark--1);
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

// Mixin for handling the background color of the social icons
@mixin social-icon-background($color) {
	rect,
	:global(.icon_youtube_svg__triangle) {
		fill: $color;
	}

	:global(.icon_instagram_svg__dot),
	:global(.icon_instagram_svg__circle) {
		stroke: $color;
	}
}

.SocialLinks_icon {
	color: var(--color-action--1); // The default color for the icons, as the icons use currentColor for their fill

	svg {
		@include social-icon-background(var(--color-white));
	}

	&:hover,
	&:focus,
	&:active {
		svg {
			@include social-icon-background(var(--color-action--2));
		}
	}
}

.SocialLinks___theme-light .SocialLinks_icon {
	color: var(--color-white);

	svg {
		@include social-icon-background(var(--color-action--1));
	}

	&:hover,
	&:focus,
	&:active {
		svg {
			@include social-icon-background(var(--color-action--2));
		}
	}
}
